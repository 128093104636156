import React, { Component } from 'react';
import UAParser from 'ua-parser-js'
import './App.css';
import regeneratorRuntime from "regenerator-runtime";

export default class App extends Component {
  render() {
    const uaParser = new UAParser()
    uaParser.setUA(window.navigator.userAgent)
    if (uaParser.getOS().name === 'iOS')
      window.location='https://apps.apple.com/us/app/lx-productscan/id1474681522?ls=1 '

    if (uaParser.getOS().name === 'Android')
      window.location='https://play.google.com/store/apps/details?id=com.locatorx.productscan'

    return (
      <div className="App">
          <h2>This link is intended to run from a mobile device</h2>
      </div>
    );
  }
}